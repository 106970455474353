<template>
  <form-create
      ref="form"
      :components="components"
      labelWidth="90px"
      v-model="form"
  >
    <template slot="before">
      <slot name="before"></slot>
    </template>
    <template slot="actionPingjia" slot-scope="{handleAdd,handleDel,roles}">
      <el-table-column
          fixed="right"
          label="操作"
          width="80"
      >
        <template slot-scope="{$index}">
          <el-button style="padding:5px 7px;font-size: 11px;" size="mini" icon="el-icon-plus" :disabled="roles.add" @click="handleAdd({})"></el-button>
          <el-button style="padding:5px 7px;font-size: 11px;" :disabled="roles.del" icon="el-icon-minus" size="mini" @click="handleDel($index)"></el-button>
        </template>
      </el-table-column>
    </template>
    <template slot="after">
      <slot name="after">
        <div style="text-align: center">
          <el-button type="primary" size="small" :disabled="loading">取消</el-button>
          <el-button type="primary" size="small" @click="handleSubmit" :disabled="loading">提交</el-button>
        </div>
      </slot>
    </template>
  </form-create>
</template>

<script>
import FormCreate from "@/components/com/FormCreate";
export default {
  components: {FormCreate},
  props:['unit_id'],
  data(){
    var _this = this;

    return {
      form:{},
      loading:false,
      components:[
        {name:'form-item',props:{prop:'title',label:'章节名称',required:true}},
        { name:'form-item' ,
          props:{prop:'pingjia',label:'评价标准'},
          components:[
            {name:'form-item',props:{prop:'pingjia',label:'评价标准',itemType:2,labelPosition:'top',showHeader:false,columns:[
                  {prop:'grade',label:'分值',type:'number'},
                  {prop:'appraise',label:'对应评价'},
                ],
              },
              slots:{action:'actionPingjia'}
            },
          ]
        },
        {name:'form-item',props:{prop:'pass_grade',label:'通过得分',type:'number'},callback(c){
          c.props.defaultValue=_this.form.pass_grade
        }},
        {name:'form-item',props:{prop:'sort',label:'排序',type:'number'},callback(c){
            c.props.defaultValue=_this.form.sort
        }},
      ],
    }
  },
  methods:{
    routeUpdate(){
        this.getDetail();
    },
    getDetail(){
      if(this.unit_id > 0){
        this.loading = true;
        this.$http.post('/xapi/course.course/unitInfo' ,{id:this.unit_id}).then(({data})=>{
          this.$nextTick(()=>{
             this.form = data;
          
            console.log(this.form,"this.form");
          })
          // setTimeout(()=>{
          //   this.form = data;
          //   this.form.pass_grade=Number(data.pass_grade)
          //   console.log(this.form,"this.form");
          //   // this.$forceUpdate()
          // },100)
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        this.form = Object.assign({} , this.$route.query,this.$route.params);
      }
    },
    handleSubmit(callback){
      this.loading = true;
      this.$refs.form.validate((form , valid , errors)=>{
        if(valid){
          this.$http.post('/xapi/course.course/unitSave' , {...this.$route.params,...form,id:this.unit_id}).then(({data,msg})=>{
            this.$handleMessage(msg || '章节更新成功' , 'success' );
            callback && callback instanceof Function  && callback(data);
          }).finally(()=>{
            this.loading = false;
          });
        }else if(errors.length > 0){
          this.loading = false;
          this.$handleMessage(errors[0].message , 'error');
        }else{
          this.loading = false;
        }
      } , true);
    }
  }
}
</script>

<style scoped>

</style>